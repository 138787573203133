export const GetAnnoucements = `
    query GetAnnoucements {
        GetAnnoucements {
            id
            annoucement
            createdAt
            updateAt
        }
    }
`;
